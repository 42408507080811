import './style.css';
import TopBar from './include/TopBar';
import Header from './include/Header';
import Footer from './include/Footer';
//import { useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Routes, Route } from "react-router-dom";

//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from './components/home/Home';
import About from './components/about/AboutUs';
import Media from './components/media/Media';
import Media2023 from './components/media/Media2023';
import Press from './components/media/Press';
import Ipumpnet from './components/ipumpnet/Ipumpnet';
import ContactUs from './components/contact/ContactUs';
import TeamBanner from './components/Team/TeamBanner';
import Downloads from './components/downloads/Downloads';
import AnnualReturn from './components/downloads/AnnualReturn';
import Product from './components/product/Product';
import Technology from './components/technology/TechnologyBanner';
import ScrollToTop from './include/ChangePage';
import RequestBanner from './components/requestdemo/RequestBanner';
import ApplicationBanner from './components/application/ApplicationBanner';
import CredentialsBanner from './components/credentials/CredentialsBanner';
import BannerParameters from './components/parameters/BannerParameters';
import TermsBanner from './components/terms/TermsBanner';
import PrivacyBanner from './components/privacy/PrivacyBanner';
import DisclaimerBanner from './components/disclaimer/disclaimerBanner';
import ChapterOne from './components/knowledgebase/ChapterOne';
import ChapterThree from './components/knowledgebase/ChapterThree';
import ChapterTwo from './components/knowledgebase/ChapterTwo';
import FailureOne from './components/knowledgebase/FailureOne';
import FailureTwo from './components/knowledgebase/FailureTwo';
import FailureThree from './components/knowledgebase/FailureThree';
import FailureFour from './components/knowledgebase/FailureFour';
import FailureFive from './components/knowledgebase/FailureFive';
import FailureSix from './components/knowledgebase/FailureSix';
import FailureSeven from './components/knowledgebase/FailureSeven';
import FailureEight from './components/knowledgebase/FailureEight';
import FailureNine from './components/knowledgebase/FailureNine';
import FailureTen from './components/knowledgebase/FailureTen';
import FailureEleven from './components/knowledgebase/FailureEleven';
import FailureTwelve from './components/knowledgebase/FailureTwelve';
import CareerBanner from './components/career/CareerBanner';
import VariousHead from './components/knowledgebase/VariousHead';
import GuidelinesFor from './components/knowledgebase/GuidelinesFor';
// import ProjectsBanner from './components/projects/ProjectsBanner';
import Projects from './components/projects/Projects';
import Media2024 from './components/media/Media2024';
//import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
      <ScrollToTop />
      <TopBar />
      <Header />
      {/* <Helmet>
        <title>Real-Time Pumps Performance Monitoring, & Maintenance| Pump Academy</title>
        <meta name="description" content="iPUMPNET is one stop solution for an efficient, economical, trouble free, optimized performance of the pumping station." />
      </Helmet> */}

      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/media" element={<Media />}></Route>
        <Route exact path="/media2023" element={<Media2023 />}></Route>
        <Route exact path="/media2024" element={<Media2024 />}></Route>
        <Route exact path="/Press" element={<Press />}></Route>
        <Route exact path="/downloads" element={<Downloads />}></Route>
        <Route exact path="/annualreturn" element={<AnnualReturn />}></Route>
        <Route exact path="/ipumpnet" element={<Ipumpnet />}></Route>
        <Route exact path="/contact-us" element={<ContactUs />}></Route>
        <Route exact path="/team" element={<TeamBanner />}></Route>
        <Route exact path="/products" element={<Product />}></Route>
        <Route exact path="/technology" element={<Technology />}></Route>
        <Route exact path="/request-demo" element={<RequestBanner />}></Route>
        <Route exact path="/applications" element={<ApplicationBanner />}></Route>
        <Route exact path="/credentials" element={<CredentialsBanner />}></Route>
        <Route exact path="/parameters" element={<BannerParameters />}></Route>
        <Route exact path="/terms-conditions" element={<TermsBanner />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacyBanner />}></Route>
        <Route exact path="/disclaimer" element={<DisclaimerBanner />}></Route>
        <Route exact path="/pumpacademy-ipumpnet" element={<ChapterOne />}></Route>
        <Route exact path="/sub-optimal" element={<ChapterThree />}></Route>
        <Route exact path="/indicative-major-pump" element={<ChapterTwo />}></Route>
        <Route exact path="/pump-failure-modes-excessive-power-consumption" element={<FailureOne />}></Route>
        <Route exact path="/pump-failure-modes-cavitation" element={<FailureTwo />}></Route>
        <Route exact path="/pump-failure-modes-bearing-failure" element={<FailureThree />}></Route>
        <Route exact path="/pump-failure-modes-excessive-vibration" element={<FailureFour />}></Route>
        <Route exact path="/pump-failure-modes-less-flow-yield-than-expected" element={<FailureFive />}></Route>
        <Route exact path="/pump-failure-modes-pump-operating-without-discharge" element={<FailureSix />}></Route>
        <Route exact path="/pump-failure-modes-leakage" element={<FailureSeven />}></Route>
        <Route exact path="/pump-failure-modes-seal-failure" element={<FailureEight />}></Route>
        <Route exact path="/pump-failure-modes-pumps-overheats" element={<FailureNine />}></Route>
        <Route exact path="/pump-failure-modes-corrosion" element={<FailureTen />}></Route>
        <Route exact path="/pump-failure-modes-lubrication-failure" element={<FailureEleven />}></Route>
        <Route exact path="/pump-failure-modes-imbalanced-or-damaged-shaft" element={<FailureTwelve />}></Route>
        <Route exact path="/various-head-terms" element={<VariousHead />}></Route>
        <Route exact path="/guidelines-for-proper-sump" element={<GuidelinesFor />}></Route>
        <Route exact path="/careers" element={<CareerBanner />}></Route>
        {/* <Route exact path="/projects" element={<ProjectsBanner />}></Route> */}
        <Route exact path="/projects" element={<Projects />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
