import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "motion/react"
import AOS from 'aos';
import 'aos/dist/aos.css';
// import "../components/ChatBot/ChatBot.css";

const GetTouch = () => {
    const [showChatBOt, setShowChatBOt] = useState(false);
    const [chatHistory, setChatHistory] = useState([{ "content": "Hello! What would you like to know about Pump Academy?", "sender": "bot" }]);
    const [userInput, setUserInput] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        AOS.init();
    }, [])

    const API_URL = "http://192.168.1.188:5000/chat";

    const addMessage = (content, sender) => {
        setChatHistory((prevHistory) => [...prevHistory, { content, sender }]);
    };

    const sendMessage = async () => {
        if (!userInput.trim()) return;

        const userQuery = userInput.trim();
        addMessage(userQuery, "user");
        setUserInput("");
        setLoading(true);

        try {
            const response = await fetch(API_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ query: userQuery }),

            });
            const data = await response.json();
            addMessage(data.response, "bot");
        } catch (error) {
            addMessage("Error occurred. Please try again.", "bot");
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") sendMessage();
    };

    return (
        <>
            <div className="getaquotebt">
                <Link to='/contact-us' id="request_a_proposal_cofee_track_button">
                    <img src="images/phone_icon.webp" alt="Get in Touch" title="Get in Touch" />
                </Link>
            </div>
            <div className="chatbot invisible">
                <img onClick={() => setShowChatBOt(true)} className='w-1/3 lg:w-1/6 h-fit fixed bottom-0 md:bottom-3 lg:bottom-5 right-8 md:right-6 lg:right-5 cursor-pointer p-4 md:p-5 lg:p-5 z-50' src="images/chat-bot.png" alt="chatbot" />
            </div>

            {/* Chatbot Modal */}
            {showChatBOt && (
                <>
                    <div className="fixed inset-0 z-50 p-4 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white rounded-lg shadow-lg max-w-lg w-full">
                            {/* Header */}
                            <div className="flex justify-between items-center p-4 border-b">
                                <h3 className="text-xl font-semibold text-gray-700">
                                    Pump Academy Chatbot
                                </h3>
                                <button
                                    onClick={() => setShowChatBOt(false)}
                                    className="text-gray-500 hover:text-gray-700 text-xl"
                                >
                                    &times;
                                </button>
                            </div>

                            {/* Chat History */}
                            <div className="p-4 h-96 overflow-y-auto border-b" data-aos="fade-up"
                                data-aos-anchor-placement="bottom-bottom">
                                {chatHistory.map((message, index) => (

                                    <motion.div
                                        animate={{
                                            scale: 1,
                                            transition: { duration: 1 }
                                        }}
                                        key={index}
                                        className={`mb-4 ${message.sender === "user"
                                            ? "text-right"
                                            : "text-left flex items-start"
                                            }`}
                                    >


                                        {
                                            message.sender === "bot" && (
                                                <img
                                                    src="images/chat-icon.png"
                                                    alt="Bot Logo"
                                                    className="w-12 h-12 mr-2"
                                                />
                                            )
                                        }
                                        < div
                                            className={`px-4 py-2 rounded-lg max-w-xs inline-block ${message.sender === "user"
                                                ? "bg-blue-500 text-white self-end"
                                                : "bg-gray-200 text-gray-800"
                                                }`}
                                        >
                                            {message.sender === "bot" &&
                                                message.content.startsWith("- ") ? (
                                                <ul className="list-disc pl-4">
                                                    {message.content
                                                        .split("\n")
                                                        .map((line, i) => (
                                                            <li key={i}>{line.substring(2)}</li>
                                                        ))}
                                                </ul>
                                            ) : (
                                                message.content
                                            )}
                                        </div>
                                    </motion.div>
                                ))}
                                {loading && <p className="text-gray-500 text-sm">Bot is typing...</p>}
                            </div>

                            {/* Input Section */}
                            <div className="flex items-center p-4 space-x-3">
                                <input
                                    type="text"
                                    placeholder="Type your question about Pump Academy..."
                                    className="w-1/2 md:w-full lg:w-full  flex-grow border border-gray-300 rounded-md px-4 py-2 focus:ring-2 focus:ring-blue-400 focus:outline-none"
                                    value={userInput}
                                    onChange={(e) => setUserInput(e.target.value)}
                                    onKeyDown={handleKeyPress}
                                />
                                <motion.div
                                    // className=""
                                    // whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.95 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                    onClick={sendMessage}
                                    className="box bg-green-500 w-fit text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none"
                                >
                                    Send
                                </motion.div>
                            </div>
                        </div>
                    </div >
                </>
            )}
        </>
    );
};

export default GetTouch;
