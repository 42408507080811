import { Link } from "react-router-dom";

const Media = () => {
    return (
        <>
            <div className="mediatitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>News 2025</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Media</Link>
                                    </li>
                                    <li>
                                        <Link to="/Media">News</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container media-sec ">
                <div className="row no-gutters">
                    <div className="col-md-5">&nbsp;</div>
                    <div className="col-md-2">&nbsp;</div>
                    <div className="col-md-5 flex justify-end gap-4 my-4 text-center">
                        <Link to="/Media2023" className="text-center shadow-md bg-blue-200 p-2 rounded-md border-1 border-blue-500">
                            <b>ARCHIVE 2023</b>
                        </Link>
                        <Link to="/Media2024" className="text-center shadow-md bg-blue-200 p-2 rounded-md border-1 border-blue-500">
                            <b>ARCHIVE 2024</b>
                        </Link>
                    </div>

                </div>
                <div className="row no-gutters ">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <ul
                            // style={{ borderTop: "1px solid #ccc" }}
                            className="list-group mb-5 pt-2 "
                        >
                            {/* //13-01-2025 update */}
                            <a
                                href="download/news/2025/Electronics Era, January 2025-Powering Sustainability_From Vision to Impact.pdf"
                                target="_blank"
                            >
                                <li className="list-group-item drop-shadow-md ">
                                    <h5>Powering Sustainability: From Vision to Impact </h5>
                                    <small>Electronics Era, January 2025</small>
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Media;
